/* this file is transformed by vux-loader */
/* eslint-disable */
export default {
  name: 'ypcg-Module',
  data() {
    return {
      data1: [],
      data2: [],
      show: false,
      item: {}
    };
  },
  props: {
    busKey: String
  },
  created() {
    this.getHistory();
  },
  methods: {
    showPopup(item) {
      this.item = item;
      this.show = true;
    },
    getHistory() {
      var url = _baseUrl + "/customizeProc/purRequisition/purRequisition/getJson.do?ids=" + this.busKey;
      this.$ajax.post(url).then(res => {
        console.log(res.data);
        this.data1 = res.data;
        var data_save = {}; //提交审批的时候用到的数据，如果那里数据不对，就在这里进行创建
        data_save.id = this.data1.id;
        data_save.cgsqdbh = this.data1.cgsqdbh;
        data_save.cgsqr = this.data1.cgsqr;
        data_save.cgsqrbm = this.data1.cgsqrbm;
        data_save.cgrq = this.data1.cgrq;
        data_save.cgsy = this.data1.cgsy;
        data_save.bz = this.data1.bz;
        data_save.cgdzj = this.data1.cgdzj; //这个字段没取到。
        this.$store.commit('setErrorModuleData', data_save);
      });
      let urlGet = _baseUrl + `/customizeProc/supIosTab/supIosTab/findByCgid.do?cgid=` + this.busKey + `&pageIndex=0&pageSize=100000&sortField=&sortOrder`;
      this.$ajax.post(urlGet).then(resGet => {
        console.log(resGet.data.data);
        //this.cgwpDatas = resGet.data.data;
      });
    },

    dateToTime(cTime) {
      let date = new Date(cTime);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;
      date = year + '-' + month + '-' + day;
      //console.log(date); // 2018-10-09
      return date;
    }
  }
};