/* this file is transformed by vux-loader */
/* eslint-disable */
import Vue from 'vue';
import { RxUtil } from "@/assets/util.js";
import { loadFormInst, format } from '@/assets/app.js';
import router from '@/router/index';
import '@/components/common/form';
import '@/components/common';
import FwygypcgModule from '@/components/bpm/ErrorModule/fwygypcg.vue'; //服务与供应品采购
Vue.component(FwygypcgModule.name, FwygypcgModule);
import YpcgModule from '@/components/bpm/ErrorModule/ypcg.vue'; //用品采购
Vue.component(YpcgModule.name, YpcgModule);
import ApproveOpinions from '@/components/bpm/ApproveOpinions';
Vue.component(ApproveOpinions.name, ApproveOpinions);
import Ypcg from "../../view/form/Ypcg";
// import QjjcList from "../../view/common/qjhcInfo/QjjcList.vue";
Vue.component(Ypcg.name, Ypcg);
// Vue.component(QjjcList.name,QjjcList);

import QjhcjhModule from '@/components/bpm/ErrorModule/qjhcjh.vue'; //期间核查计划
Vue.component(QjhcjhModule.name, QjhcjhModule);
export default {
  data() {
    return {
      switchModule: {
        fwygypcg: false,
        //服务与供应品采购 开关
        ypcg: false,
        qjhcjh: false //期间核查计划
      },

      bpmTypeName: '',
      tabbarBtn: true,
      title: "流程实例",
      bpmInst: {},
      showC: true,
      showAlert: false,
      isFromMe: false,
      isStartByMe: false,
      isAllowDoRevoke: false,
      currentViews: [],
      formVms: [],
      //form显示与隐藏
      otherShow: true,
      ypcgShow: false,
      qjhcShow: false,
      ypcgMessge: {},
      wpLists: [],
      user_No: '',
      instId_: '',
      busKey: '' //上一页列表的所有数据
    };
  },

  created() {
    this.busKey = this.$route.params.busKey;
    console.log(this.busKey);
    this.bpmTypeName = this.$route.params.treeName; //模块名称
    console.log(this.bpmTypeName);
    this.user_No = RxUtil.getCache("userNo");
    this.instId_ = this.$route.params.instId_;

    //type 数据 为 inst,act
    let type = this.$route.params.type;
    let instId = this.$route.params.instId;
    console.log(type + "     " + instId);
    var url = "";
    if (type == "inst") {
      url = _baseUrl + "/mobile/bpm/getInstInfo.do?instId=" + instId;
    } else {
      url = _baseUrl + "/mobile/bpm/getInstInfo.do?actInstId=" + instId;
    }
    this.$ajax.get(url).then(response => {
      this.newComponents(); //界面展示有问题的模块，换组件展示
      this.handResult(response, this);
      console.log(response.data.formModels[0].formKey);

      //     if(response.data.formModels[0].formKey=="PUR_REQUISITION"){ //用品采购
      // //if(response.data.formModels[0].formKey=="ypcg"){
      // 	this.otherShow=false;
      // 	this.ypcgShow=true;
      // 	let busKey = response.data.bpmInst.busKey;
      // 	let urlwp= _baseUrl+`/customizeProc/purRequisition/purRequisition/getJson.do?ids=${busKey}`;
      //   this.$ajax.post(urlwp).then(resData=>{
      // 		this.ypcgMessge = resData.data;
      // 		this.ypcgMessge.cgrq = format(this.ypcgMessge.cgrq);
      // 		storage.setItem("ypcgMessge",JSON.stringify(this.ypcgMessge));
      // 	})
      // 	let urlWPlist = _baseUrl+`/customizeProc/supIosTab/supIosTab/findByCgid.do?cgid=${busKey}&pageIndex=0&pageSize=1000000`;
      // 	this.$ajax.post(urlWPlist).then(resList=>{
      // 		this.wpLists = resList.data.data;
      // 		storage.setItem("wpLists",JSON.stringify(this.wpLists));
      // 	})
      // }else if(response.data.formModels[0].formKey=="QJHCJHSP"){
      // 	this.otherShow=false;
      // 	this.qjhcShow=true;
      // }
    }).catch(function (error) {
      globalVm.errorToast(error.response.data, 1000);
    });
  },
  computed: {
    hideBar: function () {
      return this.$store.state.pageModule.keyboard;
    }
  },
  methods: {
    newComponents() {
      if (this.bpmTypeName == '服务与供用品采购') {
        this.otherShow = false;
        this.switchModule.fwygypcg = true; //开启对应的组件
      } else if (this.bpmTypeName == '办公及固定资产采购申请') {
        this.otherShow = false;
        this.switchModule.ypcg = true; //开启对应的组件
      } else if (this.bpmTypeName == '设备期间核查计划') {
        this.otherShow = false;
        this.switchModule.qjhcjh = true; //开启对应的组件
      }
    },

    recoverInst() {
      var url = _baseUrl + "/bpm/core/bpmInst/recoverInst.do";
      var params = "instId=" + this.bpmInst.instId;
      this.$ajax.post(url, params).then(response => {
        if (response.data.success) {
          this.successToast(response.data.message, 1500, 'index', {
            from: 'instinfo'
          });
        } else {
          this.successToast(response.data.message, 1500);
        }
      });
    },
    changeInst() {
      var url = _baseUrl + "/bpm/core/bpmInst/changeInst.do";
      var params = "instId=" + this.bpmInst.instId;
      this.$ajax.post(url, params).then(response => {
        if (response.data.success) {
          this.successToast(response.data.message, 1500, 'index', {
            from: 'instinfo'
          });
        } else {
          this.successToast(response.data.message, 1500);
        }
      });
    },
    handResult(response, vm) {
      var rtn = response.data;
      if (rtn.result == "noForm") {
        //vm.$emit("point");
        this.errorToastBack('流程没有配置手机表单，请前往PC端配置');
      } else {
        const formModels = rtn.formModels;
        var bpmInst = rtn.bpmInst;
        this.isFromMe = rtn.isFromMe;
        this.isStartByMe = rtn.isStartByMe;
        this.isAllowDoRevoke = rtn.isAllowDoRevoke;
        vm.bpmInst = bpmInst;
        //vm.title=bpmInst.subject;
        this.formModels = formModels;
        loadFormInst(this, true);
        console.log(this.currentViews);
      }
    },
    clickMore() {
      this.showMore = !this.showMore;
    }
  },
  components: {
    //		PointOut
  }
};